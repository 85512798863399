/* Adjust Menu (red) text color, (Garamond) font-family, (1.5em) font-size  */
.xops-mobile .navbar .nav > li > a {
    font-size: 1.5em;
  }

  .xops-mobile .navbar-missions-header {
    display: flex; 
    justify-content: space-between; 
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: -0.5px;
  }

  .xops-mobile .navbar-missions-buttons {
    display: flex;
    flex-wrap: wrap;
  }
  
  .xops-mobile .table > thead > tr > th, .xops-mobile .table > tbody > tr > th, .xops-mobile .table > tfoot > tr > th, .xops-mobile .table > thead > tr > td, .xops-mobile .table > tbody > tr > td, .xops-mobile .table > tfoot > tr > td {
    padding: 2px;
  }
  .xops-mobile .table > thead > tr > th, .xops-mobile .table > tbody > tr > th, .xops-mobile .table > tfoot > tr > th {
    background-color: #F0F0F0;
    border-color: #ddd;
  }
  .xops-mobile .alert {
    font-size: large;
  }
  .xops-mobile .margin-bottom {
    margin-bottom: 3px !important;
  }
  .xops-mobile .no-left-right-padding {
    padding-left: 0px;
    padding-right: 0px;
  }
  @media screen and (max-width: 400px) {
    .xops-mobile .clock {
      font-size: 200%;
      float: right;
      margin-top: 4px;
      margin-right: 0px;
      margin-left: 5px;
      vertical-align: middle;
    }
    .xops-mobile .navbar-toggle {
      margin-right: 3px;
      margin-bottom: 0px;
      margin-top: 4px;
    }
    .xops-mobile .tdEventDescriptionWidth {
      width: 150px;
    }
    .xops-mobile .flightLine {
      font-weight: bold;
      font-size: x-large;
    }
  }
  
    .xops-mobile {     
      font-size: 16px;     
    }
    .xops-mobile .btn, .span {
      font-size: 16px;
      font-weight: bold;
    }

    .xops-mobile .label {
      font-size: 16px;
    }
    .xops-mobile .badge {
      font-size: 16px;
    }
    .xops-mobile .flightLine {
      font-weight: bold;
    }
 
  @media screen and (min-width: 401px) {
    .xops-mobile .btn-xl {
      padding: 15px 25px;
      font-size: 20px;
      border-radius: 8px;
    }
    .xops-mobile .navbar-toggle {
      margin-right: 3px;
      margin-bottom: 0px;
      margin-top: 4px;
    }
    .xops-mobile .tdEventDescriptionWidth {
      width: 220px;
    }
   }
  .xops-mobile .deleteEvDescriptor {
    position: initial;
    line-height: inherit;
  }
  .xops-mobile .uppercaseInputTransform {
    text-transform: uppercase;
  }
  .xops-mobile .panel-body-left-padding {
    padding-left: 15px;
  }
  .xops-mobile .container-fluid-mobile {
    padding-right: 2px;
    padding-left: 2px;
    /* This is to disallow the blue border that appears on table td in firefox on ctrl + click (http://stackoverflow.com/questions/9451841/strange-blue-border-on-firefox) */
  }
  .xops-mobile td {
    -moz-user-select: -moz-none;
  }
  .xops-mobile td * {
    -moz-user-select: text;
  }
  .xops-mobile .panel-heading {
    padding: 3px 15px !important;
  }
  .xops-mobile .no-padding-margin {
    margin-bottom: -5px !important;
    margin-top: -10px !important;
    padding: 0 !important;
  }
  .xops-mobile .padding-stop-name {
    padding: 1px;
    padding-left: 7px;
    padding-right: 7px;
  }
  .xops-mobile .padding-parameterDesc {
    margin-bottom: 5px;
    padding: 0;
  }
  .xops-mobile .no-padding-buttons {
    margin-bottom: -10px;
  }
  .xops-mobile .no-padding {
    padding: 0;
  }
  .xops-mobile .nav > li > a.mission2-status-padding {
    padding: 5px 5px;
  }
  .xops-mobile .smaller-padding-important {
    padding: 1px 0px 1px 0px !important;
  }
  .xops-mobile .bigger-padding-important {
    padding: 15px 0px 15px 0px !important;
  }
  .xops-mobile .mission-content-padding {
    padding-top: 2px;
    margin-bottom: 2px;
  }
  .xops-mobile .handPointer {
    cursor: pointer;
  }
  .xops-mobile .btn-sun {
    text-shadow: none;
    background-image: linear-gradient(to bottom, #004681, #004681 100%);
    background-repeat: repeat;
  }
  .xops-mobile .border-color-sun {
    border-color: #489CDF !important;
    border-width: medium !important;
  }
  .xops-mobile .btn-info-sun {
    text-shadow: none;
    background-image: linear-gradient(to bottom, #004681, #004681 100%);
    background-repeat: repeat;
  }
  .xops-mobile .btn-info-sun:active, .xops-mobile .btn-info-sun.active {
    background-color: #004681;
    border-color: #28a4c9;
  }
  .xops-mobile .stop-panel-body {
    padding: 5px;
  }
  .xops-mobile .padding-bot {
    padding-bottom: 10px;
  }
  .xops-mobile .padding-bot-double {
    padding-bottom: 20px;
  }
  .xops-mobile .labelLikeButton {
    padding: 5px 10px;
    line-height: 1.5;
    border-radius: 3px;
    display: inline-block;
    margin-bottom: 0px;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    line-height: 1.42857;
    border-radius: 4px;
  }
  .xops-mobile .task-table-condensed > thead > tr > th, .xops-mobile .task-table-condensed > tbody > tr > th, .xops-mobile .task-table-condensed > tfoot > tr > th, .xops-mobile .task-table-condensed > thead > tr > td, .xops-mobile .task-table-condensed > tbody > tr > td, .xops-mobile .task-table-condensed > tfoot > tr > td {
    padding: 1px;
  }
  .xops-mobile .newMission {
    background-color: #f1a9a9;
    border: 2px solid #f16a67;
  }
  .xops-mobile .viewedMission {
    background-color: #ffd08a;
    border: 2px solid #ffa215;
  }
  .xops-mobile .startedMission {
    background-color: #ffffbb;
    border: 2px solid #d9d900;
  }
  .xops-mobile .finishedMission {
    background-color: #9bff9b;
    border: 2px solid #3f8d3f;
  }
  .xops-mobile .canceledMission {
    background-color: #CCCCFF;
    border: 2px solid #4B0082;
  }
  .xops-mobile .insideTrieurMission, .xops-mobile .exitedTrieurMission {
    background-color: #ffffbb;
    border: 2px solid #d9d900;
  }
  .xops-mobile .messageHistory {
    background-color: #CCCCCC;
    border: 2px solid #000000;
  }
  .xops-mobile .commentMessageHistory {
    background-color: #99d6ff;
    border: 2px solid #000000;
  }
  .xops-mobile .anchorList {
    list-style-type: none;
  }
  .xops-mobile .newMissionHeaderList, .xops-mobile .newObjectHeaderList, .xops-mobile .updatedStopHeaderList {
    font-size: 20px !important;
  }
  .xops-mobile .newMissionList, .xops-mobile .newObjectList, .xops-mobile .updatedStopList {
    list-style: inside;
    padding-left: 0;
    font-size: 17px;
  }
  .xops-mobile .padding-bottom {
    margin-top: 0px;
    padding: 0;
  }
  .xops-mobile .no-padding-top {
    margin-bottom: 0px;
  }
  .xops-mobile .missionEventsDescriptor-btn, .xops-mobile .stopEventsDescriptor-btn {
    margin-right: 5px;
    margin-bottom: 5px;
  }
  .xops-mobile .taskEventsDescriptor-btn {
    margin-right: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .xops-mobile .padding-right {
    padding-right: 5px;
  }
  .xops-mobile .taskEntrySpacing {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .xops-mobile .well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #F5F5F5;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05) inset;
  }
  .xops-mobile .resize-block {
    resize: none;
  }
  .xops-mobile .round-button {
    border-radius: 50%;
  }
  .xops-mobile .toggle-btn {
    background: #ccc;
    cursor: pointer;
    border-top: solid 2px #eaeaea;
    border-left: solid 2px #eaeaea;
    border-bottom: solid 2px #777;
    border-right: solid 2px #777;
    padding: 1px 4px;
    margin-right: 15px;
  }
  .xops-mobile .toggle-btn.down {
    background: #bbb;
    border-top: solid 2px #777;
    border-left: solid 2px #777;
    border-bottom: solid 2px #eaeaea;
    border-right: solid 2px #eaeaea;
    margin-right: 15px;
  }
  .xops-mobile .urgencyLevelUnknown {
    background-color: #FFFFFF;
  }
  .xops-mobile .manualFlightTreatment {
    background-color: #FFFF74;
  }
  .xops-mobile .violetFlight {
    background-color: #B19CD9;
  }
  .xops-mobile .blueFlight {
    background-color: #6699FF;
  }
  .xops-mobile .orangeFlight {
    background-color: #FFCC00;
  }
  .xops-mobile .redFlight {
    background-color: #FF4040;
  }
  .xops-mobile .greenFlight {
    background-color: #009933;
  }
  .xops-mobile .greyFlight {
    background-color: #AFAFAF;
  }
  .xops-mobile .flightSeparator {
    border-bottom: solid 4px black;
  }
  .xops-mobile .flightArrivalIcon {
	transform: rotate(135deg);
  }
  .xops-mobile .flightDepartureIcon {
	transform: rotate(45deg);
  }
  .xops-mobile .commentText {
    font-size: small;
  }
  .xops-mobile .color-blue {
    color: #489CDF;
  }
  .xops-mobile .color-red {
    color: #CF3838;
  }
  .xops-mobile .color-black {
    color: #000000;
  }
  .xops-mobile .font-weight-bold {
    font-weight: bold;
  }
  .xops-mobile .label-important {
    background-color: red;
    vertical-align: middle;
  }
  .xops-mobile .label {
    font-size: 100%;
  }
  .xops-mobile .label-stop {
    border: solid;
  }
  .xops-mobile .label-success {
    background-color: green;
    vertical-align: middle;
  }
  .xops-mobile .no-bottom-spacing {
    margin-bottom: -25px;
  }
  @media screen and (max-width: 1385px) {
    .xops-mobile .table-responsive {
      width: 100%;
      margin-bottom: 15px;
      overflow-x: scroll;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      border: 1px solid #ddd;
    }
    .xops-mobile .table-responsive > .table {
      margin-bottom: 0;
    }
    .xops-mobile .table-responsive > .table > thead > tr > th, .xops-mobile .table-responsive > .table > tbody > tr > th, .xops-mobile .table-responsive > .table > tfoot > tr > th, .xops-mobile .table-responsive > .table > thead > tr > td, .xops-mobile .table-responsive > .table > tbody > tr > td, .xops-mobile .table-responsive > .table > tfoot > tr > td {
      white-space: nowrap;
    }
    .xops-mobile .table-responsive > .table-bordered {
      border: 0;
    }
    .xops-mobile .table-responsive > .table-bordered > thead > tr > th:first-child, .xops-mobile .table-responsive > .table-bordered > tbody > tr > th:first-child, .xops-mobile .table-responsive > .table-bordered > tfoot > tr > th:first-child, .xops-mobile .table-responsive > .table-bordered > thead > tr > td:first-child, .xops-mobile .table-responsive > .table-bordered > tbody > tr > td:first-child, .xops-mobile .table-responsive > .table-bordered > tfoot > tr > td:first-child {
      border-left: 0;
    }
    .xops-mobile .table-responsive > .table-bordered > thead > tr > th:last-child, .xops-mobile .table-responsive > .table-bordered > tbody > tr > th:last-child, .xops-mobile .table-responsive > .table-bordered > tfoot > tr > th:last-child, .xops-mobile .table-responsive > .table-bordered > thead > tr > td:last-child, .xops-mobile .table-responsive > .table-bordered > tbody > tr > td:last-child, .xops-mobile .table-responsive > .table-bordered > tfoot > tr > td:last-child {
      border-right: 0;
    }
    .xops-mobile .table-responsive > .table-bordered > tbody > tr:last-child > th, .xops-mobile .table-responsive > .table-bordered > tfoot > tr:last-child > th, .xops-mobile .table-responsive > .table-bordered > tbody > tr:last-child > td, .xops-mobile .table-responsive > .table-bordered > tfoot > tr:last-child > td {
      border-bottom: 0;
    }
  }
  .xops-mobile .custom-view {
    position: absolute;
    top: 0px;
    left: -20px;
    overflow: visible;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
  }
  .xops-mobile .scrollable-element {
    min-height: 70px;
    overflow-y: scroll;
  }
  .xops-mobile .input-xs {
    height: 13px;
  }
  .xops-mobile .listItem {
    background: #ffdf71;
    border: 1px solid #FFBC59;
  }
  .xops-mobile .listItem-blue {
    background: #79cef7;
    border: 1px solid #68c4f0;
  }
  .xops-mobile .listItem-cabin {
    background: #faa500;
    border: 1px solid #FFBC59;
  }
  .xops-mobile .scrollableTable {
    min-width: 100%;
    overflow-x: scroll;
    display: block;
  }
  .xops-mobile .scrollableTd {
    min-width: 100px;
  }
  .xops-mobile .blink_mission {
    -webkit-animation-name: blinker_mission;
    -webkit-animation-duration: 2s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-name: blinker_mission;
    -moz-animation-duration: 2s;
    -moz-animation-timing-function: linear;
    -moz-animation-iteration-count: infinite;
    animation-name: blinker_mission;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    border: 2px solid #ef5350;
  }
  @-moz-keyframes blinker_mission {
    0% {
      background-color: #ef9a9a;
    }
    50% {
      background-color: #ffcdd2;
    }
    100% {
      background-color: #e57373;
    }
  }
  @-webkit-keyframes blinker_mission {
    0% {
      background-color: #ef9a9a;
    }
    50% {
      background-color: #ffcdd2;
    }
    100% {
      background-color: #e57373;
    }
  }
  @keyframes blinker_mission {
    0% {
      background-color: #ef9a9a;
    }
    50% {
      background-color: #ffcdd2;
    }
    100% {
      background-color: #e57373;
    }
  }
  .xops-mobile .validatedTask {
    background-color: #7ad67a;
  }
  .xops-mobile .display-none {
    display: none;
  }
  .xops-mobile .form-control-login {
    height: 34px;
    float: none;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    color: #555;
    background-color: #FFF;
    background-image: none;
    border: 1px solid #CCC;
    border-radius: 4px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  }
  .xops-mobile .back-button-padding {
    left: 0px;
    margin-top: 4px;
    vertical-align: middle;
    margin-left: 4px;
  }
  .xops-mobile .hrIdentifier {
    font-size: 16px;
    display: inline;
    vertical-align: middle;
    text-align: center;
  }
  .xops-mobile .clock {
    font-size: 160%;
    float: right;
    margin-top: 4px;
    margin-right: 4px;
    margin-left: 4px;
    vertical-align: middle;
  }
  .xops-mobile .minibulle-col {
    padding-left: 5px;
    padding-right: 5px;
    display: inline;
  }
  .xops-mobile .version {
    font-size: 100%;
    float: right;
    margin-top: 4px;
    vertical-align: middle;
    color: #000;
  }
  .xops-mobile .container > .navbar-header, .xops-mobile .container-fluid > .navbar-header, .xops-mobile .container > .navbar-collapse, .xops-mobile .container-fluid > .navbar-collapse {
    margin-right: 0px;
    margin-left: 0px;
  }
  .xops-mobile a.stopEventsDescriptor-btn > span.glyphicon:before {
    vertical-align: text-bottom;
    color: black;
  }
  
  .xops-mobile .container-fluid {
    margin-right: 0px;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .xops-mobile body {
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none;
  }
  .xops-mobile .navbar-default {
    margin-right: 0px;
    min-height: 45px;
  }
  .xops-mobile .colorButton-orange.active {
    background: #C78200;
    border-color: #C78200;
  }
  .xops-mobile .crewBaggage {
    text-align: center;
    margin-bottom: 20px;
  }
  .xops-mobile .scanCrewBaggage {
    color: initial;
    white-space: normal;
  }
  .xops-mobile .normalBaggage, .xops-mobile .reservedBaggage {
    width: 90%;
    height: 70px;
    color: initial;
    white-space: normal;
    font-size: 30px !important;
  }
  .xops-mobile .receivedReturnedBaggage {
    width: 90%;
    color: initial;
    padding-right: 2px;
    margin-bottom: 4px;
    white-space: normal;
    font-size: 30px !important;
  }
  @media screen and (min-width: 768px) {
    .xops-mobile .navbar-header {
      float: none;
    }
    .xops-mobile .navbar-left {
      float: left;
    }
    .xops-mobile .navbar-right {
      float: none !important;
    }
    .xops-mobile .navbar-toggle {
      display: inline-block;
    }
    .xops-mobile .navbar-collapse {
      border-top: 1px solid transparent;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    }
    .xops-mobile .navbar-fixed-top {
      top: 0;
      border-width: 0 0 1px;
    }
    .xops-mobile .navbar-collapse.collapse {
      display: none !important;
    }
    .xops-mobile .collapse.in {
      display: block !important;
    }
    .xops-mobile .tdEventDescriptionWidth {
      width: 400px;
    }
    .xops-mobile .minibulle-col {
      padding-left: 15px;
      padding-right: 15px;
      display: inline;
    }
  }
  .xops-mobile .combobox-value, .xops-mobile .combobox-option {
    font-size: 200%;
  }
  .xops-mobile .center-label {
    text-align: center;
    padding-top: 10%;
    font-size: 200%;
    display: block;
  }
  .xops-mobile .bigRadioButton {
    width: 2em;
    height: 2em;
  }
  .xops-mobile .fixed-label {
    white-space: normal;
  }
  .xops-mobile .verticalButton {
    margin: 10px;
    width: 70%;
  }
  .xops-mobile .btn-xl-center {
    padding: 15px 25px;
    font-size: 25px;
    border-radius: 8px;
    top: 50%;
    left: 30%;
    position: relative;
  }
  .xops-mobile .glyphicon-grey-dot:before {
    content: "\25cf";
    font-size: 4em;
    color: grey;
  }
  .xops-mobile .glyphicon-green-dot:before {
    content: "\25cf";
    font-size: 4em;
    color: green;
  }
  .xops-mobile .glyphicon-red-dot:before {
    content: "\25cf";
    font-size: 4em;
    color: red;
  }
  .xops-mobile .serverConnexionBar {
    width: 100%;
    height: 8px;
    margin-top: 2px;
  }
  .xops-mobile .serverConnexionBar-online {
    background-color: green;
  }
  .xops-mobile .serverConnexionBar-offline {
    background-color: red;
  }
  .xops-mobile .checkList {
    padding-top: 5%;
  }
  .xops-mobile .checkListHeader > tr > th {
    background-color: #8A8A8A !important;
    color: white;
  }
  .xops-mobile .item-center {
    text-align: center;
  }
  .xops-mobile .submitCheckList {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .xops-mobile .preChecklistFlightInput {
    text-transform: uppercase;
  }
  .xops-mobile .navInventory:nth-child(even) {
    background-color: #efefef;
  }
  .xops-mobile .center-div {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 40%;
    height: 15%;
  }
  